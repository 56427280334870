import 'bootstrap'; 
import $ from 'jquery';
import EventController from './EventController';
import ModalChartManager from './ModalChartManager';

$(function () {
  const buttons = $('.rivercheck-chart-button');
  const modalManager = new ModalChartManager('rivercheck-modal');

  const eventController = new EventController(buttons, modalManager);

  
});
