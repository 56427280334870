//import { Chart, ChartConfiguration, TimeScale, LinearScale, LineController, PointElement, LineElement, TooltipItem, TooltipModel, Title } from 'chart.js';
import Chart from 'chart.js/auto';
import { de } from 'date-fns/locale';
import 'chartjs-adapter-date-fns';
import $ from 'jquery';
//Chart.register(PointElement, LineElement, LineController, LinearScale, TimeScale);
export default class ChartManager {
    constructor(canvasElement, dataManager) {
        this.chart = null;
        this.context = canvasElement.getContext('2d');
        this.dataManager = dataManager;
    }
    async createChart() {
        $('.rivercheck-spinner').removeClass('d-none');
        $('.rivercheck-spinner').addClass('d-flex');
        const data = await this.dataManager.fetchData();
        console.log('data: ', data);
        if (this.chart) {
            this.chart.destroy(); // Bestehendes Diagramm zerstören, falls vorhanden
        }
        const config = {
            type: 'line',
            data: data.chartData,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: `Verlauf des Messwertes '${data.parameter.title} (${data.parameter.unit})' an ${data.location.category_name} ${data.location.title}`,
                        font: {
                            size: 16
                        }
                    },
                    tooltip: {
                        position: 'nearest',
                        enabled: true,
                        mode: 'index',
                        intersect: false,
                        bodyFont: {
                            size: 14
                        },
                        titleFont: {
                            size: 16
                        }
                    }
                },
                scales: {
                    x: {
                        type: 'time',
                        adapters: {
                            date: {
                                locale: de
                            }
                        },
                        time: {
                            parser: 'dd.MM.yyyy HH:mm',
                            unit: 'month',
                            tooltipFormat: 'dd.MM.yyyy HH:mm',
                            displayFormats: {
                                day: 'dd.MM',
                                month: 'MMM yyyy'
                            }
                        }
                    },
                    y: {
                        beginAtZero: false,
                        title: {
                            display: true,
                            text: data.parameter.unit,
                            font: {
                                size: 16
                            },
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false
            }
        };
        $('.rivercheck-spinner').removeClass('d-flex');
        $('.rivercheck-spinner').addClass('d-none');
        this.chart = new Chart(this.context, config); // Neues Diagramm erstellen
    }
    destroyChart() {
        if (this.chart) {
            this.chart.destroy();
            this.chart = null;
        }
    }
}
