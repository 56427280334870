import $ from 'jquery';
import ModalChartManager from './ModalChartManager';
export default class EventController {
    constructor($buttonElement) {
        this.$buttonElement = $buttonElement;
        this.setupEventListeners();
    }
    setupEventListeners() {
        this.$buttonElement.on('click', async (event) => {
            try {
                const $button = $(event.delegateTarget);
                this.modalManager = new ModalChartManager('rivercheck-modal', $button.data('apiurl'), $button.data('location'), $button.data('parameter'));
                this.modalManager.openModal();
                console.log('button: ', $button);
                //const fullApiUrl = `${button.data('apiurl')}?location_id=${location_id}&parameter_id=${parameter_id}`;
                //const rawData = await this.dataManager.fetchData(fullApiUrl);
                //console.log('rawData: ', rawData);
                //const processedData = this.dataManager.processData(rawData);
                //console.log('processedData: ', processedData);
                //this.modalManager.initializeModal(processedData);
            }
            catch (error) {
                console.error('Error fetching or processing data: ', error);
            }
        });
        $('.close').on('click', () => {
            this.modalManager.closeModal();
        });
    }
}
