import { Modal } from 'bootstrap';
import ChartManager from './ChartManager';
import DataManager from './DataManager';
export default class ModalChartManager {
    constructor(modalElementId, apiBaseUrl, locationId, parameterId) {
        this.modalModule = null;
        this.chartManager = null;
        this.apiBaseUrl = '';
        this.locationId = 0;
        this.parameterId = 0;
        this.modalElement = document.getElementById(modalElementId);
        this.chartCanvas = document.getElementById('rivercheck-chart');
        this.apiBaseUrl = apiBaseUrl;
        this.locationId = locationId;
        this.parameterId = parameterId;
        this.initializeModal();
    }
    initializeModal() {
        this.modalModule = new Modal(this.modalElement, {
            backdrop: true // true: Klick auf Hintergrund schließt Modal
        });
        this.modalElement.addEventListener('hidden.bs.modal', () => {
            var _a;
            console.log('hidden.bs.modal');
            if (this.chartManager) {
                (_a = this.chartManager) === null || _a === void 0 ? void 0 : _a.destroyChart();
                this.chartManager = null;
            }
        });
    }
    openModal() {
        var _a, _b;
        const dataManager = new DataManager(this.apiBaseUrl, this.locationId, this.parameterId);
        this.chartManager = new ChartManager(this.chartCanvas, dataManager);
        (_a = this.chartManager) === null || _a === void 0 ? void 0 : _a.createChart();
        (_b = this.modalModule) === null || _b === void 0 ? void 0 : _b.show();
    }
    closeModal() {
        var _a, _b;
        console.log('closeModal');
        (_a = this.modalModule) === null || _a === void 0 ? void 0 : _a.hide();
        if (this.chartManager) {
            (_b = this.chartManager) === null || _b === void 0 ? void 0 : _b.destroyChart();
            this.chartManager = null;
        }
    }
}
