import RiverCheckChartData from "./RiverCheckChartData";
export default class DataManager {
    constructor(apiBaseUrl, locationId, parameterId) {
        this.apiBaseUrl = '';
        this.locationId = 0;
        this.parameterId = 0;
        this.apiBaseUrl = apiBaseUrl;
        this.locationId = locationId;
        this.parameterId = parameterId;
    }
    async fetchData() {
        try {
            const fullApiUrl = `${this.apiBaseUrl}?location_id=${this.locationId}&parameter_id=${this.parameterId}`;
            const response = await fetch(fullApiUrl);
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            const jsonData = await response.json();
            const data = this.processData(jsonData);
            return data;
        }
        catch (error) {
            console.error('There was a problem with the fetch operation: ', error);
            throw error;
        }
    }
    processData(rawData) {
        const parameter = rawData.data.parameter;
        const location = rawData.data.location;
        const findings = rawData.data.findings;
        const labels = findings.map((item) => {
            const [day, month, year, hour, minute] = item.date_time.split(/[.: ]/);
            return new Date(`${month}/${day}/${year} ${hour}:${minute}`);
        });
        const data = findings.map((item) => parseFloat(item.value));
        const chartData = {
            labels: labels,
            datasets: [
                {
                    label: `${parameter.title} (${parameter.unit})`,
                    data: data,
                    fill: false,
                    borderColor: 'rgb(75, 192, 192)',
                    tension: 0.1
                }
            ]
        };
        const riverCheckChartData = new RiverCheckChartData();
        riverCheckChartData.chartData = chartData;
        riverCheckChartData.parameter = parameter;
        riverCheckChartData.location = location;
        return riverCheckChartData;
    }
}
